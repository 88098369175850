@import '../../../../../scss/Variables';

.game-start-dialog {
  .task-error {
    .MuiListItemText-secondary {
      color: $error-secondary;
    }
  }

  .task-success {
    .MuiListItemText-secondary {
      color: $success-secondary;
    }
  }

  .task-warning {
    .MuiListItemText-secondary {
      color: $warning-secondary;
    }
  }

  .game-start-dialog__grid {
    button {
      width: 100%;
    }
  }
}
