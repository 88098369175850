@import '../../../../scss/BreakPoints';
@import '../../../../scss/Variables';

.expansion-panel {
  .expansion-panel__summary {
    flex-basis: 33.33%;
    flex-shrink: 0;

    .premium-expansion-title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      svg {
        margin-right: 0;
      }
    }

    > div {
      @include xs {
        flex-wrap: wrap;
      }
    }

    svg {
      margin-right: 1rem;
    }

    .subtitle {
      @include xs {
        flex-basis: 100%;
        flex-grow: 1;
      }
      margin-left: auto;
      color: $light-gray;
    }
  }
}
