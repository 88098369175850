@import '../../../../scss/Variables';

.sk-circle {
  position: relative;
  margin: 100px auto;
  width: 40px;
  height: 40px;

  .sk-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::before {
      display: block;
      animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
      margin: 0 auto;
      border-radius: 100%;
      background-color: $primary;
      width: 15%;
      height: 15%;
      content: '';
    }
  }

  .sk-circle2 {
    transform: rotate(30deg);

    &::before {
      animation-delay: -1.1s;
    }
  }

  .sk-circle3 {
    transform: rotate(60deg);

    &::before {
      animation-delay: -1s;
    }
  }

  .sk-circle4 {
    transform: rotate(90deg);

    &::before {
      animation-delay: -0.9s;
    }
  }

  .sk-circle5 {
    transform: rotate(120deg);

    &::before {
      animation-delay: -0.8s;
    }
  }

  .sk-circle6 {
    transform: rotate(150deg);

    &::before {
      animation-delay: -0.7s;
    }
  }

  .sk-circle7 {
    transform: rotate(180deg);

    &::before {
      animation-delay: -0.6s;
    }
  }

  .sk-circle8 {
    transform: rotate(210deg);

    &::before {
      animation-delay: -0.5s;
    }
  }

  .sk-circle9 {
    transform: rotate(240deg);

    &::before {
      animation-delay: -0.4s;
    }
  }

  .sk-circle10 {
    transform: rotate(270deg);

    &::before {
      animation-delay: -0.3s;
    }
  }

  .sk-circle11 {
    transform: rotate(300deg);

    &::before {
      animation-delay: -0.2s;
    }
  }

  .sk-circle12 {
    transform: rotate(330deg);

    &::before {
      animation-delay: -0.1s;
    }
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
