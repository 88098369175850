html,
body {
  margin: 0;
  height: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}
