.loading-button {
  position: relative;
  width: 100%;

  &__progress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12px 0 0 -12px;
  }
}
