@import '../../../../scss/Variables';

.game__settings {
  .number-input {
    input {
      font-size: 2rem;
    }
  }

  .prefer-favourites {
    .MuiSwitch-track {
      background-color: $primary400;
    }
    .MuiSwitch-colorPrimary {
      .MuiSwitch-thumb {
        background-color: $primary;
      }
    }
    .extra-info {
      display: flex;
      svg {
        margin-right: 4px;
      }
    }
  }

  .game__time {
    .MuiSwitch-colorPrimary {
      &.Mui-checked + .MuiSwitch-track {
        background-color: $light-gray;
      }
    }
  }
}
