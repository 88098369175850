@import '../../../scss/Variables';

.auth-page {
  .auth-page__form-wrapper {
    .MuiFormControl-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;

      .auth-page__title {
        color: $primary;
      }

      .avatar {
        margin: 1rem;
        background: $primary-gradient-bg;
        width: 50px;
        height: 50px;
        color: $light;
      }
    }

    .privacy-policy__checkbox {
      margin-top: 2rem;
      width: 100%;

      .MuiTypography-body1 {
        font-size: 0.8rem;
      }
    }
  }
}
