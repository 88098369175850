@import '../../../scss/BreakPoints';

.home__extras__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .MuiButton-contained.success-button,
  p {
    z-index: 20;
  }
}

.home-page {
  background-image: url('../../../media/svg/home.svg');
  background-repeat: no-repeat;
  background-position: 120% 180%;
  background-size: 80%;
  padding-top: 2rem;
  padding-bottom: 0;

  @include md {
    background-position: 120% 130%;
  }
}

.home-page__play-for-free {
  position: relative;
  .extras__content--bg {
    > div {
      background-image: url('../../../media/svg/whiteboard.svg');
      background-repeat: no-repeat;
      background-size: 40%;
      background-position: center;
      width: 100%;
      height: 100%;
      right: -30%;
      bottom: -50px;
      position: absolute;

      @include md {
        position: static;
        background-size: contain;
        right: 0;
        bottom: 0;
      }
    }
  }
}

.home-page__add-tasks {
  position: relative;

  .extras__content--bg {
    > div {
      background-image: url('../../../media/svg/wireframe.svg');
      background-repeat: no-repeat;
      background-size: 40%;
      background-position: center;
      width: 100%;
      height: 100%;
      left: -30%;
      bottom: -30%;
      position: absolute;

      @include md {
        position: static;
        background-size: contain;
        left: 0;
        bottom: 0;
      }
    }
  }
}

.home-page__secondary {
  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
  }

  .title {
    text-align: center;
    font-size: 2rem;
    font-weight: 300;

    @include md {
      font-size: 4rem;
    }
  }
}
