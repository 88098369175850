@import '../../../../scss/Variables';

.main-bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  border-top: 2px solid $light;
  background: $primary-gradient-bg;
  width: 100%;

  .active,
  .MuiBottomNavigationAction-root.Mui-selected {
    color: $light;
  }
}
