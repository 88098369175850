@import '../../../../scss/Variables';

.header {
  flex-grow: 1;
  max-height: 64px;

  .menu-button {
    margin-right: 2rem;
  }

  .title {
    flex-grow: 1;
  }

  a {
    text-decoration: none;
    color: inherit;
    font-weight: 300;
  }
}

.header__drawer {
  .MuiDrawer-paper {
    background: $primary-gradient-bg;
  }

  .MuiListItem-root {
    padding: 0;

    a,
    button {
      width: 100%;
      height: 100%;
      text-decoration: none;
    }

    button {
      padding: 1rem 2rem;
    }
  }
}
