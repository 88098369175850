.task-content {
  .task-title {
    &.MuiTypography-root.MuiTypography-h2 {
      padding: 0 0 2rem;
      font-size: 2rem;
    }
  }

  .categories-badges {
    .MuiChip-root {
      margin: 0 0.5rem 0.5rem 0;
    }
  }
}
