@import '../../../../scss/BreakPoints';
@import '../../../../scss/Variables';

.page-heading {
  position: relative;
  background: $deep-dark;
  background-image: url('../../../../media/svg/wave2.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 200%;
  height: 20rem;

  @include sm {
    background-position: 0 5rem;
    background-size: cover;
    height: 25rem;
  }

  .svg__wave1 {
    fill: $main-bg-color;
  }

  &.MuiContainer-root {
    display: flex;
  }

  .page-heading__title {
    position: absolute;
    z-index: 2;
    width: 100%;
  }

  svg {
    align-self: flex-end;
    animation: animateWave 1000ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
    width: 100%;
    transform-origin: bottom;

    @include xl {
      position: absolute;
      bottom: -50%;
      left: -20%;
      width: 120%;
    }
  }

  @keyframes animateWave {
    0% {
      transform: scale(1, 0);
    }

    100% {
      transform: scale(1, 1);
    }
  }
}
