@import '../../../scss/Variables';
@import '../../../scss/BreakPoints';

.profile {
  .profile__grid-container {
    flex-direction: column;
    @include md {
      flex-direction: row;
    }
  }

  .profile__grid-item {
    flex-grow: 1;
  }

  .profile__card-info {
    position: relative;
    margin-bottom: 1rem;
    overflow: visible;

    .card-user {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .default-cursor {
      button {
        cursor: default;
      }
    }

    .card-actions {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        flex: 1;
      }

      .MuiButton-label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .counter {
        font-size: 2rem;
      }

      .text {
        color: $light-gray;
        font-size: 0.6rem;
      }
    }
  }

  .profile__settings {
    .setting-switcher {
      background: $light;

      .setting-switcher__tab {
        &:not(.Mui-selected) {
          color: $primary400;
        }
      }
    }
  }
}
