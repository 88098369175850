@import '../../../../scss/Variables';

.dialog {
  .MuiDialog-paper {
    z-index: 100;
    background: $primary-gradient-bg;
  }

  .dialog__title {
    .MuiIconButton-root {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
    }
  }
}
