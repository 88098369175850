@import '../../../../scss/BreakPoints';
@import '../../../../scss/Variables';

.footer {
  margin-top: auto;
  margin-bottom: 56px;
  background: $secondary-gradient-bg;

  .svg__revert-wave1 {
    fill: $main-bg-color;
  }

  @include md {
    margin-bottom: 0;
  }
}
