@import './Variables';

.primary-gradient-bg {
  background: $primary-gradient-bg;
}

.main-bg-color {
  background-color: $main-bg-color;
}

.MuiButton-contained {
  &.Mui-disabled {
    &.error-button,
    &.success-button,
    &.warning-button {
      background: $light-gray;
      color: $deep-dark;
    }
  }

  &.error-button {
    background: $error-gradient-bg;
    color: $light;

    &:hover {
      background: $error-secondary-gradient-bg;
    }
  }

  &.success-button {
    background: $success-gradient-bg;
    color: $light;

    &:hover {
      background: $success-secondary-gradient-bg;
    }
  }

  &.warning-button {
    background: $warning-gradient-bg;
    color: $light;

    &:hover {
      background: $warning-secondary-gradient-bg;
    }
  }
}

img {
  max-width: 100%;
}
